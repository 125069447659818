var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
var BackToTopContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .back-to-top-btn {\n    position: fixed;\n    background: transparent;\n    width: 55px;\n    bottom: 0;\n    z-index: 9999;\n    left: 80%;\n    img {\n      width: 54px;\n      height: 54px;\n    }\n    @media (max-width: 767px) {\n      margin-bottom: 35px;\n    }\n  }\n\n  .float-right {\n    float: right !important;\n  }\n"], ["\n  .back-to-top-btn {\n    position: fixed;\n    background: transparent;\n    width: 55px;\n    bottom: 0;\n    z-index: 9999;\n    left: 80%;\n    img {\n      width: 54px;\n      height: 54px;\n    }\n    @media (max-width: 767px) {\n      margin-bottom: 35px;\n    }\n  }\n\n  .float-right {\n    float: right !important;\n  }\n"])));
export var BackToTop = function () {
    var _a = __read(useState(false), 2), visible = _a[0], setVisible = _a[1];
    var VISIBLE_HIEGHT = 100;
    var toggleVisible = function () {
        var _a;
        var scrolled = (_a = document === null || document === void 0 ? void 0 : document.documentElement) === null || _a === void 0 ? void 0 : _a.scrollTop;
        if (scrolled > VISIBLE_HIEGHT && visible === false) {
            setVisible(true);
        }
        if (scrolled <= VISIBLE_HIEGHT && visible === true) {
            setVisible(false);
        }
    };
    useEffect(function () {
        document === null || document === void 0 ? void 0 : document.addEventListener('scroll', toggleVisible);
        return function () {
            document === null || document === void 0 ? void 0 : document.removeEventListener('scroll', toggleVisible);
        };
    }, [visible]);
    var handleBackToTop = function () {
        window === null || window === void 0 ? void 0 : window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };
    return (React.createElement(BackToTopContainer, null, visible && (React.createElement("div", { className: "back-to-top-btn", role: "button", onClick: handleBackToTop, id: "backToTopBtn", title: "Go to top" },
        React.createElement("img", { src: "https://www.bjs.com/assets/images/icons/BackToTop.svg", className: "float-right", alt: "", role: "presentation", width: 50, height: 50 })))));
};
var templateObject_1;
