var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useMediaQuery from '../../../../hooks/useMediaQuery';
var AppBlockStyle = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0;\n  padding: 0;\n  list-style: none;\n  display: flex;\n  align-items: center;\n  &.app-block-desktop {\n    border-left: 1px solid #ccc;\n    margin: 0;\n    padding: 0 10px;\n    width: 36%;\n\n    @media (max-width: 960px) {\n      margin: 0 14px 0px 0;\n      width: 40%;\n    }\n    img {\n      max-height: none;\n      width: 100%;\n    }\n    @media (min-width: 768px) {\n      li:last-child {\n        position: relative;\n        left: -4px;\n      }\n    }\n  }\n  &.app-block-mobile {\n    img {\n      width: 100%;\n      a {\n        display: block;\n      }\n    }\n  }\n  img {\n    max-width: 100%;\n  }\n"], ["\n  margin: 0;\n  padding: 0;\n  list-style: none;\n  display: flex;\n  align-items: center;\n  &.app-block-desktop {\n    border-left: 1px solid #ccc;\n    margin: 0;\n    padding: 0 10px;\n    width: 36%;\n\n    @media (max-width: 960px) {\n      margin: 0 14px 0px 0;\n      width: 40%;\n    }\n    img {\n      max-height: none;\n      width: 100%;\n    }\n    @media (min-width: 768px) {\n      li:last-child {\n        position: relative;\n        left: -4px;\n      }\n    }\n  }\n  &.app-block-mobile {\n    img {\n      width: 100%;\n      a {\n        display: block;\n      }\n    }\n  }\n  img {\n    max-width: 100%;\n  }\n"])));
export var AppBlock = function (_a) {
    var AppIcons = _a.AppIcons;
    var matches = useMediaQuery('(min-width: 768px)');
    var _b = __read(useState(false), 2), isDesktop = _b[0], setScreen = _b[1];
    useEffect(function () {
        setScreen(matches);
    }, [matches]);
    return (React.createElement(AppBlockStyle, { className: "" + (isDesktop
            ? 'row justify-content-center app-block-desktop'
            : 'row justify-content-center app-block-mobile') }, AppIcons === null || AppIcons === void 0 ? void 0 : AppIcons.map(function (item) { return (React.createElement("li", { className: "" + (isDesktop ? 'col-6 text-center pr-xl-2 pl-xl-2 pr-1 pl-1 pr-md-1 pl-md-1' : 'col-5 px-1') },
        React.createElement("a", { href: item === null || item === void 0 ? void 0 : item.link, target: "_blank" },
            React.createElement("img", { src: item === null || item === void 0 ? void 0 : item.icon, alt: item === null || item === void 0 ? void 0 : item.title })))); })));
};
var templateObject_1;
