var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrComponent, BrPage } from '@bloomreach/react-sdk';
import { Footer } from '../views/bjs-components/Footer';
import { FooterContext } from '../../store/footer/FooterContext';
import { environmentConst as environment } from '../../config/environments/constants';
import { getKswitchCMSHeaderFooter } from '../../utils/helper';
import { fetchBRUrlConfig } from '../../services/footerClient';
var FooterIndex = function (props) {
    // const endpointURL = getHeaderFooterEndpoint(environment.BR_ENV_BASE_URL);
    var _a, _b;
    var pageRes = React.useContext(FooterContext);
    var data = (_a = pageRes === null || pageRes === void 0 ? void 0 : pageRes.data) === null || _a === void 0 ? void 0 : _a.brCmsData;
    var _c = __read(useState(data ? environment.BR_ENV_BASE_URL : null), 2), brURL = _c[0], setBRUrl = _c[1];
    var configuration = {
        endpoint: brURL,
        httpClient: axios
    };
    var mapping = {
        Footer: Footer
    };
    var storeConf = pageRes.storeConf;
    /** sending authorizationToken & serverId in BR footer API request will make footer editable in experience manager */
    if (typeof window !== 'undefined') {
        var locationObj = new URLSearchParams(location === null || location === void 0 ? void 0 : location.search);
        var token = locationObj.get('token');
        var serverId = locationObj.get('server-id');
        // path is required only when we load app in experience manager
        if (token) {
            configuration['request'] = {
                path: "" + (location === null || location === void 0 ? void 0 : location.pathname) + (location === null || location === void 0 ? void 0 : location.search)
            };
        }
        //Br Internal Preview
        if (serverId && token) {
            configuration['authorizationToken'] = token;
            configuration['serverId'] = serverId;
        }
        //Br External Preview
        if (!serverId && token) {
            configuration['authorizationToken'] = token;
        }
    }
    useEffect(function () {
        if (!data) {
            fetchBRUrlConfig(environment.BR_STATIC_URL).then(function (res) {
                setBRUrl(res === null || res === void 0 ? void 0 : res.brConfigUrl);
            });
        }
    }, []);
    var pageResponse = getKswitchCMSHeaderFooter(storeConf, props) && (configuration === null || configuration === void 0 ? void 0 : configuration.endpoint) ? (React.createElement(BrPage, { configuration: configuration, page: (_b = pageRes === null || pageRes === void 0 ? void 0 : pageRes.data) === null || _b === void 0 ? void 0 : _b.brCmsData, mapping: mapping },
        React.createElement(BrComponent, { path: "footer" }))) : null;
    return pageResponse;
};
export default FooterIndex;
