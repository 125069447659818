var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useMemo } from 'react';
import styled from 'styled-components';
import FooterTopSection from '@components/SignUpForm';
import BackToTop from '@components/BackToTop';
import { SiteLinkDesktop } from './siteLinks/SiteLinkDesktop';
import { SiteLinkMobile } from './siteLinks/SiteLinkMobile';
var SiteLinksContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: #f1f1f1;\n  .sitelinks__desktop-container {\n    display: block;\n    background-color: #f1f1f1;\n    .links {\n      font-family: 'Roboto', sans-serif;\n      ul {\n        list-style: none;\n        padding: 0;\n        margin: 0;\n      }\n    }\n    img {\n      max-width: 100%;\n    }\n  }\n\n  .sitelinks__mobile-container {\n    background-color: #f1f1f1;\n    display: none;\n    .links {\n      font-family: 'Roboto', sans-serif;\n      ul {\n        list-style: none;\n        padding-top: 0;\n        margin: 0;\n      }\n    }\n    img {\n      max-width: 100%;\n    }\n    .copyright {\n      display: none;\n    }\n\n    .social {\n      padding-bottom: 10px;\n      padding-top: 8px;\n    }\n  }\n\n  @media only screen and (max-width: 767px) {\n    .sitelinks__desktop-container {\n      display: none;\n    }\n\n    .sitelinks__mobile-container {\n      display: block;\n    }\n  }\n"], ["\n  background-color: #f1f1f1;\n  .sitelinks__desktop-container {\n    display: block;\n    background-color: #f1f1f1;\n    .links {\n      font-family: 'Roboto', sans-serif;\n      ul {\n        list-style: none;\n        padding: 0;\n        margin: 0;\n      }\n    }\n    img {\n      max-width: 100%;\n    }\n  }\n\n  .sitelinks__mobile-container {\n    background-color: #f1f1f1;\n    display: none;\n    .links {\n      font-family: 'Roboto', sans-serif;\n      ul {\n        list-style: none;\n        padding-top: 0;\n        margin: 0;\n      }\n    }\n    img {\n      max-width: 100%;\n    }\n    .copyright {\n      display: none;\n    }\n\n    .social {\n      padding-bottom: 10px;\n      padding-top: 8px;\n    }\n  }\n\n  @media only screen and (max-width: 767px) {\n    .sitelinks__desktop-container {\n      display: none;\n    }\n\n    .sitelinks__mobile-container {\n      display: block;\n    }\n  }\n"])));
var FooterTopContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
var FooterBottomContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  .copyright {\n    color: #555;\n    margin: 20px 0 15px 40px;\n    font-size: 12px;\n    letter-spacing: 0.3px;\n  }\n  .footer-bottom {\n    align-items: center;\n    background-color: #fff;\n    margin-bottom: 0px;\n    color: #555;\n    .copyright {\n      padding-top: 20px;\n      margin-top: 0;\n      @media only screen and (max-width: 767px) {\n        margin-top: 10px;\n      }\n    }\n  }\n"], ["\n  .copyright {\n    color: #555;\n    margin: 20px 0 15px 40px;\n    font-size: 12px;\n    letter-spacing: 0.3px;\n  }\n  .footer-bottom {\n    align-items: center;\n    background-color: #fff;\n    margin-bottom: 0px;\n    color: #555;\n    .copyright {\n      padding-top: 20px;\n      margin-top: 0;\n      @media only screen and (max-width: 767px) {\n        margin-top: 10px;\n      }\n    }\n  }\n"])));
var date = new Date();
var DOCUMENT_PARAMS = ['footer'];
export function Footer(_a) {
    var _b, _c;
    var component = _a.component, page = _a.page;
    var models = component === null || component === void 0 ? void 0 : component.getModels();
    var params = component === null || component === void 0 ? void 0 : component.getParameters();
    var documents = useMemo(function () {
        return DOCUMENT_PARAMS.map(function (model) { return models && models[model]; })
            .map(function (reference) { return reference && page && page.getContent(reference); })
            .filter(Boolean);
    }, [models, page]);
    var error = useMemo(function () {
        return (params &&
            Object.entries(params).filter(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                return DOCUMENT_PARAMS.includes(key) && value;
            })
                .length > documents.length);
    }, [documents, params]);
    var NavItems = documents[0].getData();
    var siteLinkData = (NavItems === null || NavItems === void 0 ? void 0 : NavItems.categoryNavGroup) || [];
    var AppIcons = ((_b = NavItems === null || NavItems === void 0 ? void 0 : NavItems.appIcons) === null || _b === void 0 ? void 0 : _b.navItem) || [];
    var socialIcons = ((_c = NavItems === null || NavItems === void 0 ? void 0 : NavItems.socialIcons) === null || _c === void 0 ? void 0 : _c.navItem) || [];
    // console.log({ siteLinkData, AppIcons, socialIcons });
    // console.log({ killSwitchForSearchIndexPage });
    // if (!document) return page.isPreview() ? <div className="error"></div> : null;
    return (React.createElement(React.Fragment, null,
        React.createElement(FooterTopContainer, null,
            React.createElement(FooterTopSection, { isCms: true, AppIcons: AppIcons, socialIcons: socialIcons })),
        React.createElement(SiteLinksContainer, null,
            React.createElement("div", { className: "d-none d-md-block" },
                React.createElement(SiteLinkDesktop, { siteLinkData: siteLinkData })),
            React.createElement("div", { className: "sitelinks__mobile-container d-block d-md-none" },
                React.createElement(SiteLinkMobile, { siteLinkData: siteLinkData, AppIcons: AppIcons, socialIcons: socialIcons }),
                React.createElement(BackToTop, null))),
        React.createElement(FooterBottomContainer, null,
            React.createElement("div", { className: "footer-bottom" },
                React.createElement("p", { className: "copyright" },
                    "\u00A9 1997 - ",
                    React.createElement("span", null, date.getFullYear()),
                    " BJ's Wholesale Club, Inc.")))));
}
var templateObject_1, templateObject_2, templateObject_3;
