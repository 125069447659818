import React, { useContext, useEffect } from 'react';
import parse from 'html-react-parser';
import { FooterContext } from '../../../store/footer/FooterContext';
export var SiteLinksMobile = function () {
    var _a;
    var handleOpenSiteLinks = function () {
        var linksAccordion = document === null || document === void 0 ? void 0 : document.querySelector('#links-accordion');
        linksAccordion === null || linksAccordion === void 0 ? void 0 : linksAccordion.querySelectorAll('.card').forEach(function (c) {
            return c.addEventListener('click', function (e) {
                var _a, _b, _c, _d, _e, _f, _g;
                var firstAnchor = c.querySelector('a');
                if ((_a = firstAnchor === null || firstAnchor === void 0 ? void 0 : firstAnchor.classList) === null || _a === void 0 ? void 0 : _a.contains('collapsed')) {
                    (_b = firstAnchor === null || firstAnchor === void 0 ? void 0 : firstAnchor.classList) === null || _b === void 0 ? void 0 : _b.remove('collapsed');
                    firstAnchor === null || firstAnchor === void 0 ? void 0 : firstAnchor.setAttribute('aria-expanded', 'true');
                    (_d = (_c = c.querySelector('.collapse')) === null || _c === void 0 ? void 0 : _c.classList) === null || _d === void 0 ? void 0 : _d.add('show');
                }
                else {
                    (_e = firstAnchor === null || firstAnchor === void 0 ? void 0 : firstAnchor.classList) === null || _e === void 0 ? void 0 : _e.add('collapsed');
                    firstAnchor === null || firstAnchor === void 0 ? void 0 : firstAnchor.setAttribute('aria-expanded', 'false');
                    (_g = (_f = c.querySelector('.collapse')) === null || _f === void 0 ? void 0 : _f.classList) === null || _g === void 0 ? void 0 : _g.remove('show');
                }
            });
        });
    };
    useEffect(function () {
        handleOpenSiteLinks();
    }, []);
    var footerContext = useContext(FooterContext);
    var data = (_a = footerContext === null || footerContext === void 0 ? void 0 : footerContext.data) === null || _a === void 0 ? void 0 : _a.siteLinksMobileData;
    return React.createElement(React.Fragment, null, data && parse(data.marketingText));
};
