import { environmentConst as environment } from '../config/environments/constants';
export var clearCookie = function (cookieName) {
    if (document) {
        document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
};
export var validateEmail = function (email) {
    // email verification regex
    var reEmail = environment.EMAIL;
    return email === null || email === void 0 ? void 0 : email.match(reEmail);
};
export var getEnvProp = function (propName) {
    if (typeof window === 'undefined') {
        return process.env[propName];
    }
    else {
        //@ts-ignore
        return window.env[propName];
    }
};
export var getKillSwitchCMS = function (storeConf) {
    var _a, _b, _c;
    return ((_c = (_b = (_a = storeConf === null || storeConf === void 0 ? void 0 : storeConf.resultList) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.userData) === null || _c === void 0 ? void 0 : _c.KillswitchCMSHeaderFooter) || 'OFF';
};
export var getOneTrustKillSwitch = function (storeConf) {
    var _a, _b, _c;
    return ((_c = (_b = (_a = storeConf === null || storeConf === void 0 ? void 0 : storeConf.resultList) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.userData) === null || _c === void 0 ? void 0 : _c.onetrustPreferenceCentre) === 'OFF';
};
export var getKswitchCMSHeaderFooter = function (storeConf, props) {
    var KillswitchCMSHeaderFooter = getKillSwitchCMS(storeConf);
    var isKSEnabled = KillswitchCMSHeaderFooter === 'OFF';
    return isKSEnabled;
};
export var isBrowser = function () { return typeof window !== 'undefined'; };
export var getHeaderFooterEndpoint = function (baseURL) {
    var endpoint = '';
    var url = new URL(baseURL);
    //@ts-ignore
    //@ts-ignore
    if (url.search != '') {
        endpoint = url.origin + url.pathname + url.search;
    }
    else {
        endpoint = url.origin + url.pathname;
    }
    return endpoint;
};
export var isNullOrUndefinedOrEmpty = function (input) {
    if (undefined !== input && null !== input && '' !== input) {
        return false;
    }
    else {
        return true;
    }
};
