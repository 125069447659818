var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useMediaQuery from '../../../../hooks/useMediaQuery';
var SocialMediaBlockStyle = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0;\n  padding: 0;\n  list-style: none;\n  display: flex;\n  align-items: center;\n  &.sm-block-desktop {\n    width: 35%;\n    padding-left: 18px;\n    padding-right: 1.8%;\n    border-left: 1px solid #ccc;\n    @media (max-width: 990px) {\n      /* border-left: 0; */\n    }\n    @media (max-width: 960px) {\n      padding-top: 0px;\n    }\n  }\n  &.sm-block-mobile {\n    margin: 15px 0 0;\n    padding-bottom: 20px;\n    text-align: center;\n    justify-content: center;\n    li {\n      margin: 0 10px;\n    }\n    img {\n      height: 33px;\n      border-radius: 38px;\n    }\n  }\n  img {\n    max-width: 100%;\n    width: 34px;\n    border-radius: 50%;\n  }\n"], ["\n  margin: 0;\n  padding: 0;\n  list-style: none;\n  display: flex;\n  align-items: center;\n  &.sm-block-desktop {\n    width: 35%;\n    padding-left: 18px;\n    padding-right: 1.8%;\n    border-left: 1px solid #ccc;\n    @media (max-width: 990px) {\n      /* border-left: 0; */\n    }\n    @media (max-width: 960px) {\n      padding-top: 0px;\n    }\n  }\n  &.sm-block-mobile {\n    margin: 15px 0 0;\n    padding-bottom: 20px;\n    text-align: center;\n    justify-content: center;\n    li {\n      margin: 0 10px;\n    }\n    img {\n      height: 33px;\n      border-radius: 38px;\n    }\n  }\n  img {\n    max-width: 100%;\n    width: 34px;\n    border-radius: 50%;\n  }\n"])));
export var SocialMediaBlock = function (_a) {
    var socialIcons = _a.socialIcons;
    var matches = useMediaQuery('(min-width: 768px)');
    var _b = __read(useState(false), 2), isDesktop = _b[0], setScreen = _b[1];
    useEffect(function () {
        setScreen(matches);
    }, [matches]);
    return (React.createElement(SocialMediaBlockStyle, { className: "" + (isDesktop ? 'row justify-content-center sm-block-desktop' : 'sm-block-mobile') }, socialIcons === null || socialIcons === void 0 ? void 0 : socialIcons.map(function (item) { return (React.createElement("li", { className: "" + (isDesktop ? 'col-3 pl-0 pr-0' : '') },
        React.createElement("a", { href: item === null || item === void 0 ? void 0 : item.link, target: "_blank" },
            React.createElement("img", { src: item === null || item === void 0 ? void 0 : item.icon, alt: item === null || item === void 0 ? void 0 : item.title, className: "socialImg" })))); })));
};
var templateObject_1;
