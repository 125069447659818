var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Api from './api';
import { urlConstants } from './urlConstants';
import axios from 'axios';
import { initialize } from '@bloomreach/spa-sdk';
import { environmentConst as environment } from '../config/environments/constants';
export var getBRCms = function (req, brConfig) { return __awaiter(void 0, void 0, void 0, function () {
    var brServerId, apiPath, page, err_1;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 2, , 3]);
                brServerId = (req === null || req === void 0 ? void 0 : req.query) && (req === null || req === void 0 ? void 0 : req.query['server-id']);
                apiPath = null;
                if ((_a = req === null || req === void 0 ? void 0 : req.query) === null || _a === void 0 ? void 0 : _a.token) {
                    apiPath = req === null || req === void 0 ? void 0 : req.originalUrl;
                }
                console.log('Footer BR config=>', { brConfig: brConfig });
                return [4 /*yield*/, initialize({
                        endpoint: (brConfig === null || brConfig === void 0 ? void 0 : brConfig.BR_BASE_URL) || environment.BR_ENV_BASE_URL,
                        httpClient: axios,
                        path: apiPath,
                        authorizationToken: (_b = req === null || req === void 0 ? void 0 : req.query) === null || _b === void 0 ? void 0 : _b.token,
                        serverId: brServerId
                    })];
            case 1:
                page = _c.sent();
                console.log('ss_footer_cms_api_success');
                return [2 /*return*/, page.toJSON()];
            case 2:
                err_1 = _c.sent();
                console.log('ss_footer_cms_api_failure', err_1);
                return [2 /*return*/, null];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getSocialIconsDesktop = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Api.get(urlConstants.SOCIAL_LINKS_DESKTOP)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_2 = _a.sent();
                console.log(err_2);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getSiteLinksDesktop = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Api.get(urlConstants.SITE_LINKS_DESKTOP)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_3 = _a.sent();
                console.log(err_3);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getSiteLinksMobile = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Api.get(urlConstants.SITE_LINKS_MOBILE)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_4 = _a.sent();
                console.log(err_4);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var footerSignIn = function (email) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Api.post(urlConstants.FOOTER_SIGNIN, { email: email })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_5 = _a.sent();
                console.log(err_5);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var fetchBRUrlConfig = function (url) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Api.get(url)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_6 = _a.sent();
                console.log(err_6);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
