var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import FooterProvider from './store/footer/FooterProvider';
import SSRProvider from 'react-bootstrap/SSRProvider';
import FooterContainer from '@components/FooterContainer';
var isBrowser = function () { return typeof window !== 'undefined'; };
export default function Root(props) {
    var inputRef = React.useRef(null);
    var initialData = props.data;
    var RootChildren = function () { return (React.createElement(SSRProvider, null,
        React.createElement(FooterProvider, { data: initialData },
            React.createElement("footer", { className: "pb-5" },
                React.createElement(FooterContainer, __assign({}, props)))))); };
    return React.createElement(RootChildren, null);
}
