import { getEnvProp } from '../../utils/helper';
export var environmentConst = {
    WEBSITE_BASE_URL: getEnvProp('WEBSITE_BASE_URL'),
    API_BASE_URL: getEnvProp('API_BASE_URL'),
    WC_PICKUP_STORE: getEnvProp('WC_PICKUP_STORE'),
    //CART
    CART_INFO_COOKIE: getEnvProp('CART_INFO_COOKIE'),
    //USER
    USER_NAME_COOKIE: getEnvProp('USER_NAME_COOKIE'),
    USER_SIGNIN_RESPONSE_COOKIE: getEnvProp('USER_SIGNIN_RESPONSE_COOKIE'),
    USER_LOGGEDIN_COOKIE: getEnvProp('USER_LOGGEDIN_COOKIE'),
    //OTHER CONSTANTS
    EMAIL: /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/,
    //BLOOMREACH ENDPOINT
    BR_ENV_BASE_URL: getEnvProp('BR_ENV_BASE_URL'),
    BR_STATIC_URL: getEnvProp('BR_STATIC_URL'),
    ENV_BR: getEnvProp('ENV_BR')
};
