var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import './set-public-path';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import { CookiesProvider } from 'react-cookie';
import Root from './root.component';
var Main = function (props) { return (React.createElement(CookiesProvider, null,
    React.createElement(Root, __assign({}, props)))); };
var lifecycles = singleSpaReact({
    React: React,
    ReactDOM: ReactDOM,
    rootComponent: Main,
    renderType: 'hydrate',
    errorBoundary: function (err, info, props) {
        console.log('err===', err);
        console.log('info===', info);
        console.log('props===', props);
        // https://reactjs.org/docs/error-boundaries.html
        return (React.createElement("div", null, "Oops, we\u2019ve run into a technical issue. Please close the website and come back later"));
    }
});
export var bootstrap = lifecycles.bootstrap;
export var mount = lifecycles.mount;
export var unmount = lifecycles.unmount;
