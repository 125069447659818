import React from 'react';
export var FooterContext = React.createContext({
    data: {
        siteLinksDesktopData: null,
        siteLinksMobileData: null,
        socialLinksDesktop: null,
        brCmsData: null
    },
    storeConf: {},
    loading: false
});
